import React from 'react'

export default function App() {
  return (
    <>
      {/* <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
      <div className="bg-blue-500 p-4">Item 1</div>
      <div className="bg-green-500 p-4">Item 2</div>
      <div className="bg-red-500 p-4">Item 3</div>
      <div className="bg-yellow-500 p-4">Item 4</div>
    </div> */}


      {/* <div className="grid grid-cols-4">
      <div className="col-span-2 bg-blue-500 p-4">Item 1 (spans 2 columns)</div>
      <div className="bg-green-500 p-4">Item 2</div>
      <div className="bg-red-500 p-4">Item 3</div>
      <div className="bg-yellow-500 p-4">Item 4</div>
    </div> */}


      {/* <div className="grid grid-cols-3 items-center justify-center gap-4">
      <div className="bg-blue-500 p-4">Item 1</div>
      <div className="bg-green-500 p-4">Item 2</div>
      <div className="bg-red-500 p-4">Item 3</div>
    </div>
     */}



      {/* <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-auto-fit">
      <div className="bg-blue-500 p-4 mt-4 md:mt-8 lg:mt-16">Item 1</div>
      <div className="bg-green-500 p-4">Item 2</div>
      <div className="bg-red-500 p-4">Item 3</div>
      <div className="bg-yellow-500 p-4">Item 4</div>
    </div> */}



      {/* <div className="max-w-sm rounded overflow-hidden shadow-lg bg-white">
        <img className="w-full" src="https://via.placeholder.com/400x200" alt="Card image" />
        <div className="p-6">
          <div className="font-bold text-xl mb-2">Card Title</div>
          <p className="text-gray-700 text-base">
            This is some content inside the card. Cards are a great way to display information in a structured format.
          </p>
        </div>
        <div className="px-6 pt-4 pb-2">
          <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
            Learn More
          </button>
        </div>
      </div> */}


<div className="container p-4">
      <h1 className="text-3xl font-bold">Centered Content</h1>
      <p className="mt-4">
        This content is centered with automatic left and right margins.
      </p>
    </div>

    </>


  )
}
